import { Icon, IconProps } from '@chakra-ui/react';

export const InnerwellLogo = (props: IconProps) => {
   return (
      <Icon width="143px" height="24px" viewBox="0 0 108 18" {...props}>
         <path d="M0 0.0138378H3.4733V17.5138H0V0.0138378Z" />
         <path d="M6.94653 8.26384C6.94653 6.01384 6.57439 5.01384 6.57439 5.01384H9.7996C9.9249 5.50573 10.0079 6.00758 10.0477 6.51384H10.1717C10.1717 6.51384 11.4122 4.63884 14.1412 4.63884C17.3684 4.63884 19.3531 6.63884 19.3531 9.26384V17.5138H16.126V9.26384C16.126 7.38884 15.0115 6.26383 13.3969 6.26383C11.4122 6.26383 10.1717 8.26384 10.1717 8.26384V17.5138H6.94653V8.26384Z" />
         <path d="M22.5765 8.26384C22.5765 6.01384 22.2043 5.01384 22.2043 5.01384H25.4295C25.5548 5.50573 25.6378 6.00758 25.6776 6.51384H25.8017C25.8017 6.51384 27.0421 4.63884 29.7711 4.63884C32.9963 4.63884 34.9811 6.63884 34.9811 9.26384V17.5138H31.7559V9.26384C31.7559 7.38884 30.6395 6.26383 29.0269 6.26383C27.0421 6.26383 25.8017 8.26384 25.8017 8.26384V17.5138H22.5765V8.26384Z" />
         <path d="M37.462 11.2638C37.462 7.51384 40.3151 4.63884 44.1566 4.63884C47.9981 4.63884 50.8511 7.51383 50.8511 11.0138C50.8509 11.3923 50.8093 11.7696 50.7271 12.1388H40.8112C41.0593 14.5198 42.796 16.2638 45.0288 16.2638C47.5098 16.2638 48.9983 14.0138 48.9983 14.0138L49.9828 14.8888C49.9828 14.8888 48.254 17.8988 44.5326 17.8988C40.4391 17.8988 37.462 14.8888 37.462 11.2638ZM47.5038 10.2718C47.5038 7.89082 45.8912 6.27177 44.1566 6.27177C42.4219 6.27177 40.8093 7.89677 40.8093 10.2718H47.5038Z" />
         <path d="M53.2159 8.26381C53.2159 6.01381 52.8438 5.01381 52.8438 5.01381H56.069C56.1943 5.5057 56.2773 6.00756 56.3171 6.51381H56.4411C56.4411 6.51381 57.6816 4.63882 60.4106 4.63882C60.7674 4.63878 60.9344 4.61275 61.4326 4.871V5.96226L61.403 7.01382C60.8392 6.84602 60.2542 6.76181 59.6664 6.76381C57.9297 6.76381 56.4411 8.26381 56.4411 8.26381V17.5138H53.2159V8.26381Z" />
         <path d="M62.8108 4.87093H65.6658L68.5603 13.1368H68.6843L71.4133 5.0118H74.3904L77.2435 13.1368H77.3676L80.0966 5.0118H82.2054L77.8736 17.5118H75.1347L72.1576 9.0118H72.0336L69.1805 17.5118H66.4515L62.8108 7.15268V4.87093Z" />
         <path d="M82.9497 11.2638C82.9497 7.51384 85.8027 4.63884 89.6442 4.63884C93.4857 4.63884 96.3388 7.51383 96.3388 11.0138C96.3385 11.3923 96.2969 11.7696 96.2148 12.1388H86.291C86.5391 14.5198 88.2758 16.2638 90.5086 16.2638C92.9895 16.2638 94.4781 14.0138 94.4781 14.0138L95.4626 14.8888C95.4626 14.8888 93.7476 17.8988 90.0203 17.8988C85.9268 17.8988 82.9497 14.8888 82.9497 11.2638ZM92.9915 10.2718C92.9915 7.89082 91.3789 6.27177 89.6442 6.27177C87.9095 6.27177 86.297 7.89677 86.297 10.2718H92.9915Z" />
         <path d="M103.998 0H107.767V18H103.998C104.528 16.8095 104.764 15.3294 104.764 13.877C104.764 12.2897 103.998 10.4444 103.998 9C103.998 7.18453 104.764 5.8254 104.764 4.44643C104.766 2.93079 104.507 1.42631 103.998 0Z" />
         <path d="M102.053 18H98.8276V0H102.053C102.053 1.48016 102.892 2.83135 102.892 4.28373C102.892 5.87103 102.053 7.55556 102.053 9C102.053 10.8155 102.892 12.3333 102.892 13.7163C102.906 15.187 102.621 16.645 102.053 18Z" />
      </Icon>
   );
};

export const LoginScreenGraphic = (props: IconProps) => {
   return (
      <Icon
         width="392px"
         height="539px"
         fill="none"
         viewBox="0 0 392 539"
         {...props}
      >
         <g clipPath="url(#clip0_8550_172488)">
            <path
               d="M233.653 210.258C240.962 197.485 332.644 204.936 367.668 210.258C369.953 275.519 370.157 401.251 371.07 414.024C372.212 429.991 363.076 524.271 356.984 524.271C352.111 524.271 349.722 451.323 348.706 411.913L288.175 417.996C278.276 429.401 274.85 532.254 258.398 532.254C244.774 532.254 259.125 412.069 253.911 406.211C244.774 395.947 169.776 396.327 153.324 401.099L136.573 516.287C131.497 524.904 121.193 536.968 120.584 516.287C119.822 490.436 136.573 374.107 136.573 360.042C136.573 345.976 214.699 344.625 221.551 344.625C228.404 344.625 224.516 226.225 233.653 210.258Z"
               fill="#1F545E"
            />
            <path
               d="M317.949 260.083C316.334 262.553 328.624 346.261 305.643 356.93C282.662 367.6 203.818 359.4 189.041 371.699C174.264 383.999 170.162 463.624 171.793 495.632C173.424 527.64 147.988 538.309 129.093 538.309C110.198 538.309 80.6433 537.486 75.7984 531.74C70.9534 525.993 93.0465 512.047 93.0465 512.047C93.0465 512.047 93.5471 472.695 94.936 447.628C96.551 416.959 97.956 359.707 114.865 340.063"
               stroke="white"
               strokeWidth="1.19427"
               strokeMiterlimit="10"
            />
            <path
               d="M358.043 211.856C361.281 197.882 348.995 160.918 343.233 146.959C337.47 133.001 316.897 129.705 309.483 128.057C302.07 126.409 283.147 129.673 281.496 123.13C279.845 116.587 281.496 90.2857 281.496 70.5598C281.496 50.8339 273.241 40.1227 270.004 36.31C258.964 23.3856 232.127 17.9896 222.253 19.6375C212.379 21.2853 194.266 65.6323 194.266 69.7197C194.266 73.807 205.791 76.2788 205.791 76.2788L203.185 92.4343C202.996 93.6356 203.232 94.865 203.852 95.9116C204.472 96.9581 205.438 97.7564 206.584 98.1695C209.746 99.2019 212.843 100.421 215.859 101.821C220.796 104.276 219.728 119.947 218.918 122.403C218.109 124.859 164.774 131.095 158.056 132.096C138.147 135.004 126.719 214.49 122.445 217.187C118.172 219.885 83.0631 204.602 79.1135 198.318C75.1639 192.033 64.5454 184.553 59.4142 185.733C54.283 186.912 64.9339 197.526 64.9339 205.394C64.9339 213.262 42.8714 210.951 36.5586 214.861C30.2457 218.771 34.1953 227.026 40.8966 228.981C47.5979 230.936 44.4415 236.849 49.9611 239.999C55.4808 243.149 55.8693 249.046 64.9339 248.255C73.9985 247.463 84.18 242.665 94.41 251.486C104.64 260.307 126.541 264.782 126.541 264.782C152.537 264.685 160.533 212.486 165.389 214.942C170.245 217.397 171.232 248.691 165.389 260.177C159.545 271.664 161.358 288.24 161.358 288.24C161.358 288.24 64.1084 294.928 40.2491 319.581C40.2491 319.581 38.938 342.797 36.5909 392.459C34.2438 442.121 33.5964 501.994 33.5964 501.994C33.5964 501.994 -1.59367 505.095 0.0573787 514.918C1.70843 524.741 18.9149 527.277 38.7113 528.149C51.1332 528.705 63.505 530.076 75.7466 532.253"
               stroke="white"
               strokeWidth="1.19427"
               strokeMiterlimit="10"
            />
            <path
               d="M319.809 294.449C319.809 294.449 245.727 325.624 161.114 290.188"
               stroke="white"
               strokeWidth="1.19427"
               strokeMiterlimit="10"
            />
            <path
               d="M64.2015 177.822C50.8206 177.822 39.9733 166.99 39.9733 153.629C39.9733 140.267 50.8206 129.435 64.2015 129.435C77.5824 129.435 88.4297 140.267 88.4297 153.629C88.4297 166.99 77.5824 177.822 64.2015 177.822Z"
               fill="#FF9C4B"
            />
            <path
               d="M63.6015 113.706C57.9146 113.706 53.3045 109.102 53.3045 103.423C53.3045 97.7447 57.9146 93.1411 63.6015 93.1411C69.2883 93.1411 73.8984 97.7447 73.8984 103.423C73.8984 109.102 69.2883 113.706 63.6015 113.706Z"
               stroke="#FF9C4B"
               strokeWidth="1.19427"
               strokeMiterlimit="10"
            />
            <path
               d="M172.33 45.5252C169.894 51.3881 168.193 57.6326 167.341 64.144L167.933 64.2214C167.524 67.3522 167.313 70.5456 167.313 73.7884C167.313 77.0313 167.524 80.2246 167.933 83.3555L167.341 83.4329C168.193 89.9443 169.894 96.1888 172.33 102.052L172.882 101.823C175.355 107.774 178.592 113.329 182.471 118.367L181.998 118.731C185.934 123.842 190.525 128.423 195.645 132.349L196.009 131.875C201.052 135.743 206.614 138.97 212.571 141.435L212.343 141.987C218.208 144.414 224.454 146.108 230.967 146.956L231.044 146.364C234.175 146.771 237.369 146.982 240.612 146.982C243.855 146.982 247.048 146.771 250.18 146.364L250.257 146.956C256.769 146.108 263.016 144.414 268.881 141.987L268.653 141.435C274.61 138.97 280.171 135.743 285.215 131.875L285.578 132.349C290.699 128.423 295.29 123.841 299.225 118.731L298.752 118.367C302.631 113.329 305.869 107.774 308.342 101.823L308.893 102.052C311.33 96.1888 313.031 89.9443 313.882 83.4329L313.29 83.3555C313.7 80.2246 313.911 77.0313 313.911 73.7884C313.911 70.5456 313.7 67.3522 313.29 64.2214L313.882 64.144C313.031 57.6326 311.33 51.3881 308.893 45.5252L308.342 45.7543C305.869 39.8028 302.631 34.2474 298.752 29.2102L299.225 28.8458C295.29 23.7354 290.699 19.1542 285.578 15.2277L285.215 15.7015C280.171 11.8341 274.61 8.60702 268.653 6.14193L268.881 5.59017C263.016 3.1631 256.769 1.46858 250.257 0.620863L250.18 1.213C247.048 0.805446 243.855 0.59518 240.612 0.59518C237.369 0.59518 234.175 0.805446 231.044 1.213L230.967 0.620864C224.454 1.46858 218.208 3.1631 212.343 5.59017L212.571 6.14193C206.614 8.60702 201.052 11.8341 196.009 15.7015L195.645 15.2277C190.525 19.1542 185.934 23.7354 181.998 28.8458L182.471 29.2102C178.592 34.2474 175.355 39.8028 172.882 45.7543L172.33 45.5252Z"
               stroke="white"
               strokeWidth="1.19427"
               strokeDasharray="22.8 22.8"
            />
         </g>
         <defs>
            <clipPath id="clip0_8550_172488">
               <rect width="392" height="538.337" fill="white" />
            </clipPath>
         </defs>
      </Icon>
   );
};
